
html, body, #root, .App {
  height: 100%;
}

#countdown {
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 100000;
  opacity: 0.7;
}

#countdownNumber {
  position: relative;
  bottom: 80px;
}

#winner {
  position: absolute;
  left: 50%;
  margin-left: -29.885px;
}

.font-oswald {
  font-family: 'Oswald', sans-serif;
}

.max-width-576 {
  max-width: 576px;
  margin: 0 auto;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
  max-width: 544px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  background: #28a745;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  background: #28a745;
  cursor: pointer;
}
